import React from 'react'

function ErrorPage() {
  return (
    <div className="text-center">
      <div className="flex flex-column align-items-center">
        <img
          src="/eddiehub_community_logo.webp"
          alt="image"
          style={{ width: '150px' }}
        />
        <h1>Perfil não localizado.</h1>
        <h1>Se você é um novo usuário, por favor registre-se no SYNC</h1>
        <h2>
          Saiba mais em{' '}
          <a href="https://sync.com.br" target="_blank" rel="noreferrer">
            sync.com.br
          </a>
          .
        </h2>
      </div>
    </div>
  )
}

export default ErrorPage
